
body{
  font-family: 'Inter';
  margin: 0px;
  background-color: #ffffff;
  background-image: url("./assets/images/dropd-app-bg.jpg");
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
#root{ 
    max-width: 440px; 
    margin: 0 auto;
    position: relative;
}
.navbar-wrapper {
    max-width: 440px;
    width: 100%;
    margin: 0px auto;
    display: flex;
}
.inner-pages-container {
  max-width: 440px;
  width: 100%;
  margin: 0px auto;
  min-height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F6F6;
}
.inner-pages-container-wrap{
/*  min-height: calc(100vh - 65px);*/
  padding: 15px;
}
.container-bg-white{
  width: 100%;
  padding: 15px 15px;
  background-color: #ffffff;
}
.small-text{
    font-size: 10px;
    font-weight: 400;
    color: #6A7587;
}
.dropd-pink{
  color:#EC1C80
}
.dropd-violet{
  color: #4E3292;
}
.dropd-purple{
  color: #46164D;
}

.error{ 
  color: #C22A1C;
  font-size: 14px;
  margin-bottom: 20px;
 }
.success{ color: #3D9634;}

.status-icon.Failed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(255, 59, 48, 0.1); /* Light red background */
  margin: 0 auto 20px;
}

.status-icon.Failed .error-icon {
  width: 36px;
  height: 36px;
  stroke: #FF3B30; /* Standard iOS/Material Design error red */
}

.bg-pink,
.filled-pink,
.button-pink{
  background-color: #EC1C80;
}

.bg-white{
  background: #fff;
}
.border-pink{
  border: 1px solid #EC1C80;
}
.border-round-12{
  border-radius: 12px;
}

.button{
  border-radius: 12px;
  min-width: 90px;
  padding: 5px 15px;
  color: #6A7587;
  text-align: center;
}

.selected-button-styles{
  background-color: #EC1C80;
  color:white;
  border: 1px solid #EC1C80;
  border-radius: 12px;
  width:87px;
  height: 26px;
  float: right;
  margin-right: 5px;
  margin-top: 55px;
}
.floatingToolbar{
  max-width: 320px;
  width:90%;
  height: 37px;
  background-color: pink;
  position: fixed;
  transform: translate(-50%,-50%);
  z-index: 1000;
  left: 50%;
  top: 95%;
  border-radius: 18px;
}
#cursor{
  cursor: pointer;
}

@media screen and (max-width: 767px) {
    #root,
    .navbar-wrapper,
    .inner-pages-container{
      max-width: 100%;
      width: 100%;
    }
    .inner-pages-container-wrap{
      padding-left: 20px;
      padding-right: 20px;
    }
    .c-field > .MuiFormControl-root,
    .desc-field > .MuiInputBase-root{
      width: 100%;
    }
    input, select, textarea {
      font-size: 16px;
    }

  /* Sign Up */
  body .attributeicons-position{
    margin-left: auto;
    margin-right: auto;
  }

  .swipe{
    max-width: 100%;
    width: 100%;
  }
}
.navbar-wrapper > div:last-child > div{
  gap: 8px;
  padding-right: 8px;
}
.wallet-connection-label{
/*  font-size: 8px;*/
/*  line-height: 12px;*/
  color: #EC1C80;
  border: none;
/*  padding: 5px 8px;*/
/*  float: right;*/
/*  margin-right: 10px;*/
/*  margin-left: 10px;*/
/*  margin-top: 0px;*/
  background-color: #EC1C80;
  background-color: transparent;
/*  display: flex;*/
  align-items: center;
  gap: 5px;
}
.wallet-connection-label.wallet-connected{
  color: #EC1C80;
  background-color: transparent;
}

.notification-icon{
  position: relative;
}
.notification-icon .notification-count{
  width: 14px;
  height: 14px;
  position: absolute;
  top: -2px;
  right: -4px;
  border-radius: 50%;
  font-size: 8px;
  line-height: 14px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ec1d80;
}