/*transaction*/
.transaction-container{
	margin-top: 10px;
}
.transaction-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 0.5px solid #E5E5E5;
}
.transaction-row .trans-thumb-wrap{
	width: 40px;
	height: 40px;
	margin: auto 0px;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
}
.transaction-row .trans-thumb-wrap.drpd-icon{
	border-radius: 0px;
}
.transaction-row .trans-thumb-wrap img{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 0;
}
.transaction-row .trans-details-wrap{
	margin: auto 0px;
	box-sizing: border-box;
}
.transaction-row .trans-title{
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	color: #6A7587;
	margin-bottom: 5px;
}
.transaction-row .trans-title span{
	font-weight: 600;
}
.transaction-row .trans-date{
	font-size: 10px;
	line-height: 14px;
	font-weight: 400;
	color: #6A7587;
}
.transaction-row .trans-bal {
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	color: #6A7587;
	margin: auto 0px;
}
.transaction-row .trans-bal span{
	margin-left: 5px;
}
.transaction-row .trans-bal img{
	width: 10px;
}



.transaction-filter {
  display: flex;
  align-items: center;
  margin: 16px 0;
  font-family: Arial, sans-serif;
}

.transaction-filter label {
  margin-right: 10px;
  font-size: 14px;
  color: #4E3292;
  font-weight: 500;
}

.transaction-type-dropdown {
  background-color: white;
  border: 1px solid #E1D7F0;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  color: #4E3292;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;
  min-width: 180px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.transaction-type-dropdown:hover {
  border-color: #4E3292;
}

.transaction-type-dropdown:focus {
  border-color: #4E3292;
  box-shadow: 0 0 0 3px rgba(225, 215, 240, 0.5);
}

/* Style for the dropdown arrow */
.transaction-type-dropdown {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%234E3292%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.6%205.4-7.9%205.4-12.9%200-5-1.9-9.2-5.5-12.7z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 10px;
  padding-right: 30px;
}

/* Style for the options */
.transaction-type-dropdown option {
  padding: 8px;
  background-color: white;
  color: #4E3292;
}

/* Add a subtle highlight for the selected option */
.transaction-type-dropdown option:checked {
  background-color: #E1D7F0;
}