/* CSS Option 1: Pure CSS */
.profile-image-slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  background-color: #f3f4f6;
}

.slide-count {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 12px;
  line-height: 16px;
}
.profile-image-slider-container .swiper-wrapper{
	align-items: center;
}
/* Swiper Pagination for Dark Backgrounds */
.profile-image-slider .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.3); /* Light color with low opacity for dark backgrounds */
  opacity: 0.5;
  margin: 0 4px !important;
  transition: all 0.3s ease;
  border: 1px solid #EC1C80;
}

.profile-image-slider .swiper-pagination-bullet-active {
  width: 16px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.8); /* Brighter white for active state */
  border-radius: 4px;
  opacity: 1;
}

/* Explicit dark background handling */
.dark .profile-image-slider .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.3);
}

.dark .profile-image-slider .swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 0.8);
}