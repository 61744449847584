.signup-container{
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    height: 100vh;
    width: 100%;
    background-color: #ffffff;
}
.signup-wrap{
    margin: auto;
}
.signup-container .feature-box{
    max-width: 270px;
    width: 100%;
}
.signup-container .feature-box img{
    max-width: 100%;
    height: auto;
}
.signup-container .button-container{
    margin-top: 50px;
    text-align: center;
}
.signup-button{
    border-radius: 19px;
    color: white;
    border: none;
    height: 40px;
    width: 150px;
    background-color: #C387C3;
    font-size: 16px;
    font-weight: 400;
}
.alpha-water-mark {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #54319178;
    padding: 0px !important;
    margin: 0px 0px auto 10px !important;
}