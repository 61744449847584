.notfound-container{
	height: calc(100vh - 65px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.notfound-container h1{
	font-size: 32px;
	line-height: 36px;
	font-weight: 300;
	color: #EC1C80;
}
.notfound-container p{
	color: #6A7587;
}