.round-edge-col{
	margin-bottom: 30px;
	padding: 27px 20px 20px 20px;
	box-shadow: 0px 4px 10px rgb(0 0 0 / 6%);
	border-radius: 18px;
	box-sizing: border-box;
	background-color: #E1D7F0 !important;
}
.round-edge-col .balance span {
   margin: auto 5px;
}
.income-wrapper{
  margin-top: -65px;
}
.income-card-container .income-popup-conainer{
  background-color: #ffffff;
}

.income-popup-conainer .income-formula{
  background-color: #E1D7F0;
}
.income-popup-conainer.pink-bg{
  background-color: #E1D7F0 !important;
}
.income-popup-conainer.pink-bg .balance {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #4E3292;
}

.income-popup-conainer.pink-bg .income-formula{
  background-color: rgba(255, 255, 255, 0.27);
}
.income-month{
  font-size: 12px;
  font-weight: 400;
  color: rgb(106, 117, 135);
  text-align: center;
  margin-bottom: 10px; 
}
.button-wrap .button{
  width: 100%;
  color: white;
  background-color: rgb(195, 135, 195);
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: none;
}
.disabled-button {
  opacity: 0.7;
  cursor: not-allowed;
}
