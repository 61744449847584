.profile-circle {
    border-radius: 50%;
    width: 60px !important;
    height: 60px !important;
    object-fit: cover;
    display: block;
    margin: 0px auto 10px auto;
}

.flics-card {
    border-radius: 25px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.flics-header {
    justify-content: space-between;
    align-items: center;
}

.flics-id {
    font-weight: bold;
}

.flics-amount {
    font-size: 2rem;
    color: #9B5DE5;
    font-weight: bold;
    margin-bottom: 1rem;
}

.status-dot {
    height: 15px;
    width: 15px;
    background-color: #00C853;
    border-radius: 50%;
    display: inline-block;
}

.delete-btn {
    background-color: #9B5DE5;
    color: white;
    border-radius: 20px;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    width: 100%;
}

.signed-status {
    font-size: 0.9rem;
}

.signed-status svg {
    color: #00C853;
}


.profile-link,
.profile-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adjust space between text and icon */
}

.profile-name {
    font-weight: bold;
    color: #333;
    /* Adjust the color */
}

.link-icon {
    display: flex;
    background-color: transparent;
    border: none;
    color: #333;
    /* Adjust the icon color */
}

.flics-detail-left {
    color: #6A7587;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
}

.flics-detail-right {
    color: #6A7587;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
}

.flics-amount {
    color: #4E3292;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
    margin-left: 10px;
    vertical-align: middle;
}

.flics-page-container .flics-delete-action-wrap .button-wrap{
    width: 100%;
}

.flics-delete-status-wrap{
    margin-top: 30px;
}
.flics-delete-status-wrap > div{
    display: flex;
    gap: 15px;
}

.flics-delete-status-wrap > p{
    font-size: 10px;
    line-height: 14px;
    color: #6A7587;
    margin-bottom: 5px;
}
.flics-delete-status-wrap > div{
    margin-bottom: 20px;
}
.flics-delete-status-wrap .rounded-label{
    width: 50%;
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    padding: 10px 10px;
    position: relative;
    background-image: url("src/assets/images/informationcircle.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}
.rounded-label.deleted {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
  background-image: url("src/assets/images/tickcircle.svg");
}
.rounded-label.deleted .status{
    font-weight: bold;
}
.flics-delete-status-wrap .rounded-label .status{
    font-size: 10px;
    line-height: 12px;
    display: flex;
    flex-wrap: wrap;
}
.flics-delete-status-wrap .rounded-label > .user{
    font-size: 12px;
    line-height: 16px;
}

.flics-delete-status-wrap .rounded-label > .icon{
    position: absolute;
    top: 10px;
    right: 10px;
}
.flics-delete-status-wrap .rounded-label.active-action{
    border: 1px solid #00FFA3;
}

.row.flics-header .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}
.row.flics-header .col-md-2{
    flex: 0 0 auto;
    width: 16.66666667%;
}
.flics-popup-conainer .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}
.flics-popup-conainer .col-md-8{
    flex: 0 0 auto;
    width: 66.66666667%;
}