.view-match-guide-wrap {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(78, 50, 146, 0.85);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guide-skip {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.guide-icon-up {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
}

.guide-icon-down {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
}

.guide-icon-left {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
}

.guide-icon-right {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
}