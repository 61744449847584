.knowbetter-wrapper{
    width:300px;
}
.knowbetter-text-container{
    width:300px;
    /*position: absolute; */
    /*top: 17%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%); */
}
.knowbetter-text{
    font-size: 32px;
    width: 250px;
    font-weight: 600;
    color: #4e3292;
    margin-bottom: 15px;
}
.knowbetter-description{
        width: 230px;
        /*position: absolute;*/
        top: 28%;
        font-size: 14px;
        font-weight: 400;
        /*left: 41%;*/
        /*transform: translate(-50%, -50%);*/
        color: #6a7587;
}
.knowbetter-container{
    width:300px;
    /*position: absolute;*/
    /*top: 45%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.knowbetter-input-box-wrapper{
    /*position: absolute; */
    width:300px;
    /*height:600px;*/
    /*top: 80%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.input-support-text{
    padding-left: 18px;
    padding-bottom: 5px;
    font-size: 10px;
    font-weight: 400;
    color: #6a7587;
}
.marital-status{
    margin-bottom: 20px;
    width: 300px;
    height: 42px;
    border-radius: 10px;
    background-color: white;
    border:none;
    padding: 0px 15px;
}
.language-preference{
    width: 220px;
    height: 42px;
    border-radius: 0px 10px 10px 0px;
    background-color: white;
    border:none;
}

.edit-user-details-page .marital-status{
    width: 100%;
}
.edit-user-details-page .col-2-row,
.edit-user-details-page .col-3-row
{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.edit-user-details-page .col-2-row .field-wrap{
    width: 48%;
}
.edit-user-details-page .col-3-row .field-wrap{
    width: 30%;
}
.edit-user-details-page .field-wrap{
    margin-bottom: 30px;
}
.edit-user-details-page .field-wrap .input-support-text,
.edit-user-details-page .language-selection .input-support-text{
    padding-left: 0px;
}
.edit-user-details-page .field-wrap input#outlined-basic{
    border: none;
    padding: 5px 0px;
    border-bottom: 0.5px solid #E5E5E5;
    background-color: transparent;
}
.edit-user-details-page .field-wrap select{
    width: 100%;
    height: 42px;
    padding: 5px 0px;
    margin: 0px;
    border: none;
    border-bottom: 0.5px solid #E5E5E5;
    background-color: transparent;
}