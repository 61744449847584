.chat-messages {
  flex: 1;
  padding: 10px 15px 85px 15px;
  overflow-y: auto;
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.received .message-content {
  align-self: flex-start;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

.sent .message-content {
  align-self: flex-end;
  background-color: #ffe4f1;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

.message-time {
  font-size: 10px;
  color: #888;
  margin-top: 5px;
}
.message.sent .message-time{
  text-align: right;
}