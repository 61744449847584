.dashboard-content{
  width: 100%;
  background-color: #FFFFFF;
}
.dashboard-content .half-pnk{
  padding: 20px;
}
.db-section-title{
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #484848;
}
.dashboard-row{
  display: flex;
}
.db-section.dashboard-users{
  padding: 20px 0px;
}
.db-section.dashboard-activity{
  padding: 30px 0px;
}
.db-swipe-activity,
.db-total-transactions{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.db-swipe-activity > span{
  font-size: 16px;
  line-height: 100%;
  color: #AF2487;
}

.db-swipe-activity > span img,
.db-total-transactions > span img{
  margin-right: 15px;
}
.db-swipe-activity .metric-value{
  color: #AF2487;
}
.db-section.dashboard-transactions{
  padding: 40px 0px 30px 0px;
  background-color: #FAF3FF;
}
.db-section.dashboard-users-revenue{
  padding: 20px 0px 80px 0px;
  background-color: #F6F6F6;
}
.metric-item{
  padding: 15px 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  text-align: center;
}
.metric-item.bg-white{
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
}
.metric-item p:last-child{
  margin-bottom: 0px;
}
.metric-title{
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #484848;
}
.metric-value{
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #4E3292;
}
.metric-value strong{
  font-size: 32px;
  line-height: 40px;
}
.metric-value span{
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
.db-section.dashboard-users-revenue .metric-item{
  text-align: left;
}
.border-line{
  background-color: #E5E5E5;
  height: 1px;
  width: 100%; 
  display: block;
}
.border-line.margin-tb{
  margin: 10px 0px;
}