.zone-text-container{
    width:300px;
    /*position: absolute;*/
    /*top: 17%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.zone-text{
    font-size: 32px;
    width: 250px;
    font-weight: 600;
    color: #4e3292;
    margin-bottom: 15px;
}
.zone-description{
        width: 230px;
        /*position: absolute;*/
        /*top: 28%;*/
        font-size: 14px;
        font-weight: 400;
        /*left: 41%;*/
        /*transform: translate(-50%, -50%);*/
        color: #6a7587;
}
.zone-container{
    /*width:300px;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.zoneOptionLabel{
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
}
.zoneOptionWrap{
    height: 60px;
    position: relative;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 40px;
    margin-bottom: 30px;
}
.selectedSlide .zoneOptionWrap{
    background: rgba(195, 135, 195, 0.59);
}
.zoneIconWrap{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
}
.zoneIconWrap img{
    height: 40px;
    margin: auto;
}
.sliderWrap{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 0px 30px;
    box-sizing: border-box;
}
.sliderWrap > span{
    padding: 28px 0px;
}
.sliderWrap>div{
    margin-top: 7.5px;
}

.MuiSlider-thumb{
    background: radial-gradient(131.95% 116.55% at 51.29% 85.28%, #E5E5E5 30.28%, #FFFFFF 72.48%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
    border: 1px solid #E1D7F0;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
.selectedSlide .MuiSlider-thumb{
    background: radial-gradient(94.42% 83.41% at 51.37% 106.72%, #E1D7F0 0%, #DCB4D5 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
}