.attribute-text-container{
    width:300px;
}
/* .attribute-wrapper{

} */
.attribute-text{
    font-size: 32px;
    width: 250px;
    font-weight: 600;
    color: #4e3292;
    margin-bottom: 15px;
}
.attribute-description{
        width: 230px;
        font-size: 14px;
        font-weight: 400;
        color: #6a7587;
}
body .attribute-container{
/*    width:100%;*/
    width: 100%;
    margin: 0px auto;
    /* margin-bottom: 20px; */
}
body .attribute-container > img{
    width: 100%;
}
body .attributeicons-position{
    width:100%;
    display: flex;
    justify-content: start;
    gap: 25px;
    position: relative;
    margin-top: 20px;
}
body .attribute-icons{
    width: calc(20% - 20px);
    height: 40px;
    border-radius: 10px;
    background-color: white;
    position: relative;
}
body .attribute-icons:last-child{
    margin-right: 0px;
}
body .attribute-icons > img{
    width: 30px;
    display: block;
    margin: 5px auto;
}
body .attribute-icons .delete-attr-icon{
  position: absolute;
  top: -10px;
  right: -5px;
}
.attributes-main-wrap{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  align-content: space-between;
}
.attributes-main-wrap > div{
    width: 100%;
}
.attributes-entry-wrap{
  margin-top: 40px;
  margin-bottom: 20px;
}
.attributes-content-wrap{
/*  height: 450px;*/
  margin-top: auto;
  margin-bottom: auto;
}
.attributes-button-wrap{
  margin-top: auto;
  margin-bottom: 20px;
}