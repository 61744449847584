.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  left: 0px;
  bottom: 0px;
}

.input-field {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 20px;
  background-color: #f0f0f0;
  margin-right: 10px;
}

.icon {
  font-size: 24px;
  cursor: pointer;
}