.postcard{
	margin-bottom: 20px;
	background-color: #ffffff;
}

.postcard-text-container {
	padding: 15px 20px;
}
.postcard-text-container span{
    font-size: 16px;
    font-weight: 400;
    color: rgb(106, 117, 135);
    position: relative;
}
.postcard-image-container{
	width: 100%;
}
.postcard-image-container img{
	width: 100%;
	height: auto;
}
.postcard-footer-container{
    padding: 10px 15px;
    border-top: 1px solid #f1f1f1;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}