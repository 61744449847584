.inner-pages-container-wrap{
/*	width: 340px;*/
	padding-top: 15px;
}
.page-title{
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	color: #000000;
	text-align: left;
}
.club-page-wrapper{
	background-color: #ffffff;
}
.club-page-wrapper .infinite-scroll-component {
	padding-bottom: 80px;
}
.club-title-create-tab-wrap{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.club-title-create-tab-wrap button{
	width: 140px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	text-align: center;
	color: #6A7587;
	background: #FFFFFF;
	border: 1px solid #6A7587;
	box-sizing: border-box;
	border-radius: 12px;
	padding: 5px;
	margin: 0px;
	position: relative;
}
.club-title-create-tab-wrap button span{
	width: 16px;
	height: 16px;
	position: absolute;
	top: 4px;
	left: 8px;
}
.create-view-pages-tab-wrap{
	margin-bottom: 20px;
}
.club-tabs-wrap{
	display: flex;
	flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 20px;
}
.club-tabs-wrap button{
	width: 33.33%;
	font-size: 12px;
    line-height: 100%;
    font-weight: 400;
    text-align: center;
    color: #6A7587;
    background: #FFFFFF;
    border: 1px solid #6A7587;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 5px;
    position: relative;
}
.club-tabs-wrap button span{ 
	width: 16px;
	height: 16px;
	position: absolute;
	top: 4px;
	left: 8px;
}
.club-tabs-wrap button.active{
	color: #ffffff;
	border: 1px solid #ffffff;
	background-color: #ec1d80;
}
.clubs-container{
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
	box-sizing: border-box;
	padding-top: 15px;
}
.clubs-container .club-col{
	width: calc(50% - 10px);
	text-align: center;
	margin-bottom: 30px;
	border-radius: 10px;
	background-color: #ffffff;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.clubs-container .club-col h3{
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}
.clubs-container .club-col .page-likes{
	font-size: 10px;
	line-height: 14px;
	font-weight: 400;
	margin-bottom: 5px;
}
.clubs-container .club-col .page-likes span{
	font-size: 12px;
	font-weight: 600;
}
.clubs-container .club-col .round-icon{
	width: 60px;
	height: 60px;
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	margin: 0px auto 0px auto;
	transform: translateY(-10px);
}
.clubs-container .club-col .club-feature-img{
	height: 115px;
	width: 100%;
	position: relative;
	border-radius: 10px;
	overflow: hidden;
}
.clubs-container .club-col .round-icon img,
.clubs-container .club-col .club-feature-img img{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 0;
}
.clubs-container .club-col .like-page-button{	
	width: calc(100% - 20px);
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	color: #ffffff;
	position: absolute;
	left: 10px;
	bottom: 15px;
	background: #EC1C80;
	border: 1px solid #EC1C80;
	box-sizing: border-box;
	padding: 4px 4px;
	border-radius: 12px;
}

.load-more-container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.load-more-button {
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.load-more-button:hover {
	background-color: #0056b3;
}