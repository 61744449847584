.custom-dots-spinner-wrap {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 9999999;
}

.spinner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spin 1.5s linear infinite;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Position dots in a perfect circle */
.dot:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg) translate(40px) rotate(-45deg);
}
.dot:nth-child(2) {
  transform: translate(-50%, -50%) rotate(90deg) translate(40px) rotate(-90deg);
}
.dot:nth-child(3) {
  transform: translate(-50%, -50%) rotate(135deg) translate(40px) rotate(-135deg);
}
.dot:nth-child(4) {
  transform: translate(-50%, -50%) rotate(180deg) translate(40px) rotate(-180deg);
}
.dot:nth-child(5) {
  transform: translate(-50%, -50%) rotate(225deg) translate(40px) rotate(-225deg);
}
.dot:nth-child(6) {
  transform: translate(-50%, -50%) rotate(270deg) translate(40px) rotate(-270deg);
}
.dot:nth-child(7) {
  transform: translate(-50%, -50%) rotate(315deg) translate(40px) rotate(-315deg);
}
.dot:nth-child(8) {
  transform: translate(-50%, -50%) rotate(360deg) translate(40px) rotate(-360deg);
}

/* Typed text style */
.typed-text {
  font-size: 14px; 
  font-weight: 400;
  margin-top: 20px;
  text-align: center;
/*  font-family: Arial, sans-serif;*/
}

/* Dots animation style */
/*.dots {
  font-size: 1.2rem;
  animation: blink 1s step-start infinite;
}
*/
/* Blinking dots animation */
/*@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}*/
