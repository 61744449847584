.comments-wrapper {
    width: 100%;
    height: calc(100% - 65px);
    background-color: #F7F7F7;
    overflow: hidden;
    padding-bottom: 80px;
}

.comment-header-text {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
}

.comment-card-box {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    max-height: calc(100% - 65px);
}

.comment-avatar-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
}

.comments-text {
    color: #6A7587;
    font-size: 12px;
    font-weight: 400;
}

.comments-hashtags {
    font-size: 12px;
    font-weight: 400;
}

.comments-text-box {
    padding-left: 20px;
    position: relative;
    top: 10px;
    color: #AFAFAF;
}

.comment-card {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.comment-textfield-position {
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 0px;
}