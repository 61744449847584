body{
    display: block !important;
}
#root{
    max-width: 340px;
    width: 100%;
    height: 100%;
    background-color: #E1D7F0;
}
.create-page-wrapper{
	display: flex;
	flex-direction: column;
    min-height: 100vh;
}
.input-label{
     padding-left: 18px;
     padding-bottom: 5px;
     font-size: 10px;
     font-weight: 400;
     color: #6A7587;
}

.otp-wrapper{
    margin-top: 35px;
}