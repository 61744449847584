/*Shobhit*/
.signup-screen-wrapper {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 20px);
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
/*.header-wrap{
	display: flex;
	align-items: center;
    gap: 25px;
    padding: 20px 0px;
    margin-bottom: 10px;
}*/

.signup-screen-wrapper {
  flex: 1; /* Full height of the screen */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.signup-screen-wrapper  .header-wrap {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	gap: 25px;
	padding: 20px 0px;
}
.signup-screen-wrapper  .entry-wrap {
	flex-shrink: 0;
	padding-bottom: 30px;
}
/*.signup-screen-wrapper  .content-wrap {
	flex: 1;
	justify-content: center;
	overflow-y: auto;
}*/
.signup-screen-wrapper .button-wrap {
	padding-top: 30px;
	flex-shrink: 0;
}
.header-wrap .back-button{
	cursor: pointer;
}

.page-title-big{
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
	color: #4E3292;
	margin-bottom: 0px;
}
.page-title{
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	color: #000000;
	text-align: left;
}
.page-desc p{
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6A7587;
}

/*wallet subscription*/
.text-info-popup{
	width: 100%;
	padding: 40px 20px;
	background: #FFFFFF;
	box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.36);
	border-radius: 18px;
	box-sizing: border-box;
}
.text-info-popup span{
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	color: #EC1C80;
	text-align: center;
	display: block;
	margin-bottom: 0px;
}
