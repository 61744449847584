.timeline-container{
    background-color:#F7F7F7;
     height:100vh; 
    /* width:100vw; */
}
.search-box-container{
    /* width: 100vw; */
    height: 70px;
    background-color: #F6F6F6;
}
.input-container-box{
    display: flex;
    width:330px;
    border-radius: 10px;
    height:42px;
    background-color: white;
    justify-content: center;
    position: relative;
    top: 15px;
}
.input-text{
    color:#AFAFAF;
    position: relative;
    right:15px;
    top:12px;
    font-size: 14px;
    font-weight: 400;
}
.avatar-container{
    position: relative;
    transform: translate(0%,-78%);
    width: 60px;
    height: 60px;
    border-radius: 30px;
    left: 10px;
    z-index: 1000;
}
.avatar-image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 0;
    height: 60px;
    width: 60px;
    border-radius: 30px;
}
.profile-menu-icons{
    height:20px;
    width: 20px;
}
.profile-icons-container{
    width:100%;
    display: flex;
    justify-content: space-around;
    padding-top: 7px;
}
.close-icon {
    position: relative;
    left: -2%;
    top: -53%;
    display: flex;
    justify-content: end;
    cursor: pointer;
}
.timeline_error{
    position:absolute;
    transform: translate(-50%,-50%);
    left:50%;
    top:50%;
    color:red;
}
.add-friend-button{
    font-size: 12px;
    line-height: 16px;
    color:#ffffff;
    border: 1px solid #EC1C80;
    border-radius: 12px;
    padding: 5px 10px;
    float: right;
    margin-right: 10px;
    margin-top: 15px;
    background-color: #EC1C80;

}

.timeline-card-wrapper .iconalignment img{
    max-width: 34px;
    height: auto;
}
.custom-toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 250px;
}

.toast-message {
  flex-grow: 1;
}

.toast-close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}
