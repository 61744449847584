.clubs-page-wrapper {
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.club-details-wrapper {
    background-color: white;
}
.club-cover-image{
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}
.profile-image-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid white;
}

.club-info {
    padding: 10px 15px;
}

.club-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}
.club-time-details {
    color: #6A7587;
    font-size: 12px;
    margin-bottom: 10px;
}
.club-meta-info {
    font-size: 10px;
    font-weight: 400;
    color: #6A7587;
    padding-right: 10px;
}
.club-meta-info:last-child{
    padding-right: 0px;
    border-right: 0px;
}
.club-meta-info span{
    font-weight: bold;
    color: #EC1C80;
}
.club-button-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
}
.club-button-wrap:before{
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: #E0E0E0;
    position: absolute;
    left: 0px;
    top: 50%;
}
.club-button-wrap button{
    position: relative;
}
.club-join-button {
    font-size: 14px;
    line-height: 100%;
    font-weight: 400;
    color: #ffffff;
    background-color: #EC1C80;
    border: 1px solid #EC1C80;
    border-radius: 12px;
    cursor: pointer;
    padding: 10px 30px;
}
.bg-white.border-pink.button {
    font-size: 14px;
    line-height: 100%;
    font-weight: 400;
    background-color: white;
    color: #EC1C80;
    padding: 10px 30px;
}

.club-description {
    width: 100%;
    padding: 20px 15px;
    background-color: white;
}

.profile-description-text {
    font-size: 12px;
    font-weight: 400;
    color: #6A7587;
    line-height: 1.4;
}

.club-tabs {
    display: flex;
    justify-content: center;
    margin: 20px 0px 0px 0px;
    padding: 20px 15px;
    background-color: #F6F6F6;
}

.club-button {
    background-color: white;
    color: #6A7587;
    border: 1px solid #EC1C80;
    border-radius: 12px;
    width: 150px;
    height: 36px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.club-button.active {
    background-color: #EC1C80;
    color: white;
}
.club-posts-mem-wrapper{
    padding: 0px 15px;
    background-color: #F6F6F6;
}
.club-posts-mem-wrapper .member-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    background-color: #ffffff;
}
.club-posts-mem-wrapper .member-row .add-friend-button{
    margin-top: 0px;
}
/* Add new styles */
.join-club-prompt, .no-content-message {
    text-align: center;
    color: #6A7587;
    padding: 20px;
    font-size: 14px;
    background-color: #F6F6F6;
}
.pending-club-status-popup-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.pending-club-status-popup {
    width: 100%;
    max-width: 500px;
    text-align: center;
    padding: 50px 20px;
    border-radius: 20px;
    background-color: #ffffff;
    position: relative;
}

.pending-club-status-popup p {
    margin-bottom: 15px;
}

.pending-club-status-popup button {
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    border: 1px solid #EC1C80;
    border-radius: 12px;
    padding: 10px 15px;
    background-color: #EC1C80;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pending-club-status-popup button:hover {
    background-color: #c51368;
}

.pending-club-status-popup button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pending-club-status-popup .wallet-button {
    background-color: #FFFFFF;
    color: #EC1C80;
    border: 1px solid #EC1C80;
}

.pending-club-status-popup .wallet-button:hover {
    background-color: #f0f0f0;
}

.retry-limit-message {
    color: red;
    font-size: 0.9em;
    margin-top: 15px;
    text-align: center;
}

.rotating-icon {
  animation: spin 1s linear infinite;
  display: inline-block;
}
.button.edit-club{
    margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
