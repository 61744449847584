.chat-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  background-color: #fef4f9;
}
.connection-status {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    color: #888;
}