.popup{
	width: 340px;
	min-height: 240px;
	padding: 40px 20px;
	background: #FFFFFF;
	box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.36);
	border-radius: 18px;
	box-sizing: border-box;
	z-index: 9999;
	position:absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.popup span{
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	color: #EC1C80;
	text-align: center;
	display: block;
	margin-bottom: 40px;
}
.popup-wrapper{
    position: fixed;
    background-color: rgba(0,0,0,0.2);
    width:100%;
    height: 100%;
    z-index: 9999;
    left: 0;
    top: 0;
}