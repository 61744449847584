.search-page{
	min-height: 100vh;
	background-color: #F6F6F6;
}
.search-form {
	position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.search-form .search-field,
.search-form .search-field > div{
	width: 100%;
}
.search-form #outlined-basic {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 18px 15px;
    background: #FFFFFF;
    border: 1px solid #6A7587;
    box-sizing: border-box;
    border-radius: 12px;
}
.search-button {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 9px;
    right: 9px;
}

.kebab-menu-button{
	cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0px;
}
.user-row .kebab-menu-button{
	margin-left: auto;
}

/* General Styles */
.search-page .radio-list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between items */
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  padding-bottom: 30px;
  border-bottom: 1px solid #E5E5E5;
}

/* Each radio item */
.search-page .radio-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 35px; /* Space for radio button */
}

/* Hidden input */
.search-page .radio-item input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

/* Unselected Circle */
.search-page .radio-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background: #fff;
  transition: all 0.3s ease;
}

/* Selected Circle */
.search-page .radio-item.selected::before {
  border-color: #e91e63; /* Pink border for selected */
  background: #fff;
}

.search-page .radio-item.selected::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e91e63; /* Inner circle color */
}

/* Label text */
.search-page .radio-item span {
  margin-left: 10px;
}

.search-separator {
  text-align: center;
  margin: 15px 0;
}

.search-separator span {
  padding: 0 10px;
  color: #666;
}

.search-end {
  text-align: center;
  padding: 20px;
  color: #666;
}
.search-loading,
.no-results,
.search-error{
  margin-top: 15px;
}
.more-search-loading,
.search-end{
  font-weight: bold;
  padding: 20px 0px;
  text-align: center;
}