.chat-header {
  display: flex;
  align-items: center;
  padding: 10px;
/*  background-color: #fff;*/
}

.icon {
  font-size: 24px;
  cursor: pointer;
}

.profile {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-info {
  margin-left: 10px;
}

.profile-name {
  font-size: 18px;
  font-weight: bold;
}

.profile-status {
  font-size: 12px;
  color: #888;
}
.more-dots-icon{
  margin-left: auto;
}