.viewmatch-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    box-sizing: border-box; 
    overflow: hidden; 
}
.viewmatch-details-wrap {
  display: flex;
  flexDirection: column;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow-y: auto;
  margin-top: -12px;
  padding-top: 12px;
  position: relative;
}
.viewmatch-details-inner-wrap{
  width: 100%;
  position: relative;
  background-color: #f9f9f9;
}
.viewmatch-label-flics {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
}
.viewmatch-label-flics span{
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 9px;
  background-color: #EC1C80;
}
.viewmatch-image-slider {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 100%;
}
.viewmatch-image-slider .carousel-root,
.viewmatch-image-slider .carousel.carousel-slider,
.viewmatch-image-slider .carousel *{
  height: 100%;
}
.viewmatch-image-slider img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}



/*.viewmatch-container {
  min-height: calc(100vh - 65px);
  background-color: white;
}*/

.viewmatch-image-wrap{
  width: 100%;
/*  min-height: 300px;*/
/*  height: 35vh;*/
  position: relative;
  background-color: #f7d1db;
}
.viewmatch-image-wrap img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  object-position: center;
  z-index: 0;
}
.viewmatch-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.viewmatch-featured-details{
  width: 100%;
/*  height: 40px;*/
  border-radius: 10px 10px 0px 0px;
  background-color: #E1D7F0;
  position: relative;
/*  bottom: 12px;*/
  display: flex;
  justify-content: center;
/*  padding-bottom: 15px;*/
  padding: 10px 10px 35px 10px;
}
.viewmatch-featured-details .viewmatch-label-text {
    color: #662F8F;
    font-size: 12px;
    line-height: 100%;
    font-weight: 600;
}
.viewmatch-details {
 /* width: 100%;
  height: 40px;
  border-radius: 10px 10px 0px 0px;
  background-color: #E1D7F0;
  position: relative;
  bottom: 12px;
  display: flex;*/
  margin-top: -15px;
  justify-content: space-around;
  border-top: 4px solid rgb(236, 28, 128);
  position: relative;
  border-radius: 10px 10px 0px 0px;
  background-color: white;
/*  bottom: 17px;*/
}

.viewmatch-count-wrap{
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 0px;
}
.viewmatch-text {
  color: #662F8F;
  font-size: 12px;
  font-weight: 600;
  padding-top: 12px;
}

.viewmatch-avatar-container {
  position: relative;
  top: 10px;
  left: 10px;
}

.viewmatch-description {
  width: 254px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5%;
  background-color: white;
  margin-top: 20px;
}

.viewmatch-scores {
  margin-top: 10px;
  height: 30px;
  width: 100%;
  background-color: #FAF3FF;
  ;
  display: flex;
  justify-content: space-around;
}

.viewmatch-user-details {
  margin-top: 10px;
  height: 30px;
  width: 100%;
  background-color: #E1D7F0;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-around;
}

.viewmatch-items {
  font-size: 12px;
  font-weight: 600;
  color: #662F8F;
  padding-top: 7px;
}

.carousel-root {
  flex-grow: 1;
}

.carousel-root {
  flex-grow: 1;
}

.viewmatch-items-details {
  font-size: 10px;
  font-weight: 600;
  color: #6A7587;
  padding-top: 7px;
  text-transform: capitalize;
}

.viewmatch-common-traits {
  font-size: 12px;
  font-weight: 600;
  color: #6A7587;
  display: flex;
  justify-content: center;
}

.user-name {
  width: 200px
}

#root {
  width: 100%;
  /* overflow: hidden; */
}

#root>div {
  /*display: flex; */
  /*flex-direction: column;*/
  /*justify-content: center;*/
  align-items: center;
}

#root>.viewmatch-container {
  display: block;
}

.row {
  /* flex-direction: row !important; */
}

.row>* {
  /* margin: 5px; */
}

h1 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.30);
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
  max-width: 440px;
  width: 100%;
}

.cardContainer {
  width: 100%;
/*  height: 300px;*/
  min-height: 100vh;
}

.card {
  position: relative;
  background-color: #fff;
  width: 100%;
/*  height: 300px;*/
  min-height: 100vh;
  border-radius: 20px;
}

.cardContent {
  width: 100%;
  height: 100%;
}



.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  height: 28px;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 20px;
  display: flex;
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #9198e5;
  transition: 200ms;
  margin: 0 10px;
  font-weight: bolder;
  width: 160px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0% {
    transform: scale(1, 1)
  }

  10% {
    transform: scale(1.1, 1.1)
  }

  30% {
    transform: scale(.9, .9)
  }

  50% {
    transform: scale(1, 1)
  }

  57% {
    transform: scale(1, 1)
  }

  64% {
    transform: scale(1, 1)
  }

  100% {
    transform: scale(1, 1)
  }
}

.accept-match {
  font-size: 4em;
  color: green;
  text-align: center;
  z-index: 999;
}

.reject-match {
  font-size: 4em;
  color: red;
  text-align: center;
  z-index: 999;
}

.views-left {
  width: 150px;
  position: relative;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  color: white;
  border-radius: 5px 5px 0px 0px;
  background-color: #4E3292;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.views-left span{
  position: relative;
}
.viewmatch-popup-container{
  width: 100%;
  height: calc(100% - 65px);
  position: fixed;
  top: 65px;
  left: 0px;
  z-index: 9999;
}
.viewmatch-popup{
  max-width: 320px;
  width: calc(100% - 20px);
  height: calc(100% - 130px);
  padding: 40px 20px;
  border-radius: 18px;
  box-sizing: border-box;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.36);
  background-color: rgba(78, 50, 146, 0.96);
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.viewmatch-popup > * {
  flex-basis: 100%;
}
.viewmatch-popup .popup-entry{
  margin-top: auto;
  margin-bottom: auto;
}
.viewmatch-popup .popup-action{
  margin-top: auto;
  margin-bottom: auto;
}
.viewmatch-popup .popup-title{
  font-size: 36px; 
  line-height: 42px;
  color: #ffffff;
  margin-bottom: 30px;
}
.viewmatch-popup .popup-info{
  font-size: 16px;
  color: #ffffff;
}
.viewmatch-popup .popup-details,
.viewmatch-popup .success-message{
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 15px;
}
.view-match-guide-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/view-match-guide-overlay.png");
}
