.card {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.card:hover{
  background-color: #dee2e6;
  transform: scale(1.02)
}

.card img {
  width: 75px;
  height: 75px;
  margin-right: 10px;
  margin-left: 10px;
}

.card i {
  font-size: 30px;
  color: #e02b2b;
  margin-left: auto;
  cursor: pointer;
}

.modal-content .container .card{
  height: inherit;
  min-height: inherit;
}