.knowbetter-wrapper{
    width:100% !important;
}
.knowbetter-wrapper .main-wrap{
    min-height: inherit;
}
.knowbetter-wrapper .content-wrap{
    max-width: 100%;
    max-height: calc(100vh - 380px);
    overflow: auto;
}
.knowbetter-wrapper .main-wrap .button-wrap{
    margin-bottom: 0px;
}
.knowbetter-text-container{
    width:100%
    /*position: absolute; */
    /*top: 17%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%); */
}
.knowbetter-text{
    font-size: 32px;
    width: 250px;
    font-weight: 600;
    color: #4e3292;
    margin-bottom: 15px;
}
.knowbetter-description{
        width: 230px;
        /*position: absolute;*/
        top: 28%;
        font-size: 14px;
        font-weight: 400;
        /*left: 41%;*/
        /*transform: translate(-50%, -50%);*/
        color: #6a7587;
}
.knowbetter-container{
    width:100%;
    /*position: absolute;*/
    /*top: 45%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
body .knowbetter-input-box-wrapper{
    /*position: absolute; */
    width:100%;
    /*height:600px;*/
    /*top: 80%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.input-support-text{
    padding-left: 18px;
    padding-bottom: 5px;
    font-size: 10px;
    font-weight: 400;
    color: #6a7587;
}
.marital-status{
    margin-bottom: 20px;
    width: 100% !important;
    height: 42px;
    border-radius: 10px;
    background-color: white;
    border:none;
    padding: 0px 15px;
}
.language-preference{
    width: 220px;
    height: 42px;
    border-radius: 0px 10px 10px 0px;
    background-color: white;
    border:none;
}
.error-list {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}
.error-list ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.error-list li {
  color: #d32f2f;
  font-size: 14px;
  margin-bottom: 5px;
}

.referral-code-wrap{
    margin: 20px -20px 0px -20px;
    padding: 15px 20px 20px 20px;
    background-color: rgba(255, 255, 255, 0.40);
}