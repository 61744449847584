body{
    align-items: flex-start;
}
.profile-page-wrapper{
    height:auto;
/*    width: 100vw;*/
    display: flex;
    flex-direction: column;
    background-color: white;
}
.profile-page-wrapper > div{
    width: 100%;
    max-width: 100%;
}
.profile-description{
    width:254px;
    padding-left: 5%;
    background-color: white;
    margin-top: 20px;
}
.profile-description-text{
    padding-top: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #6A7587;
}
.iconalignment{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.profile-button{
    font-size: 14px;
    line-height: 18px;
    padding: 5px 5px;
    background-color: white;
    color:#6A7587;
    border:1px solid #EC1C80;
    border-radius: 12px;
    width:100px;
}
.profile-button.active{
    color: #ffffff;
    background-color: #EC1C80;
}
.avatar-container-box{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: pink;
    position: relative;
    top: 10px;
    left: 10px;
}
.profile-image-box{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 0;
}
.profile-cover-image{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 0;
}
.online-status{
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #90EE90;
    margin-top: 17px;
    margin-left: 10px;
}
.couple-image-position{
    float: right;
    padding-right: 20px ;
    padding-top: 10px;
}
.profile-button-styles{
    background-color: #EC1C80;
    color:white;
    border: 1px solid #EC1C80;
    border-radius: 12px;
    width:87px;
    height: 26px;
    float: right;
    margin-right: 10px;
    margin-top: 15px;
}
.profile-user-name{
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
    width:fit-content;
}
.profile-personal-details{
    display: flex;
    justify-content: space-around;
    background-color: #E1D7F0;
    /* height: 40px; */
    position: relative;
    top:12px;
}
.personal-details-text{
    color: #6A7587;
    font-size: 12px;
    font-weight: 600;
}

.chat-icon{
    color: #ffffff;
    background-color: #EC1C80;
    padding: 2px 6px;
    border-radius: 5px;
    margin: auto 10px 0px 0px;
}
.chat-icon svg{
    width: 15px;
    height: 15px;
}
.posts-club-page-container{
    padding-bottom: 70px;
    background-color: rgb(247, 247, 247);
}

.clubcard-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0px 20px;
}

.clubcard-wrapper > div{
    width: 100%;
    padding: 10px 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 11px;
    border-radius: 12px;
}
.no-content{
    padding: 30px 0px;
    text-align: center;
}
.user-timeline-details{
    box-shadow: none;
}
.user-gender{
    font-size: 10px;
    font-weight: 400;
    color: #EC1C80;
    text-transform: capitalize;
}