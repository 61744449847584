.gender-text-container{
    width:300px;
    /*position: absolute;*/
    /*top: 17%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.gender-text{
    font-size: 32px;
    width: 200px;
    font-weight: 600;
    color: #4e3292;
    margin-bottom: 15px;
}
.gender-description{
        width: 300px;
        /*position: absolute;*/
        /*top: 22%;*/
        font-size: 14px;
        font-weight: 400;
        /*left: 50%;*/
        /*transform: translate(-50%, -50%);*/
        color: #6a7587;
}
.gender-container{
    width: 100%;
/*    width:300px;*/
    /*position: absolute;*/
    /*top: 45%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.gender{
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid white;
    border-radius: 10px;
    color: #484848;
    font-size: 16px;
    line-break: 100%;
    font-weight: 600;
    height: 42px;
    margin-top: 10px;
}
.selectedgender{
    background-color: #C387C3;;
    border: 1px solid white;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    line-height: 100%;
    font-weight: 600;
    height: 42px;
    margin-top: 10px;
}
.gender-text-style{
    position: relative;
    left: 30px;
    top:12px
}