.genderpreferences-text-container{
    width:300px;
    /*position: absolute;*/
    /*top: 17%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.genderpreferences-text{
    font-size: 32px;
    width: 200px;
    font-weight: 600;
    color: #4e3292;
    margin-bottom: 15px;
}
.genderpreferences-description{
        width: 300px;
        /*position: absolute;*/
        /*top: 25%;*/
        font-size: 14px;
        font-weight: 400;
        /*left: 50%;*/
        /*transform: translate(-50%, -50%);*/
        color: #6a7587;
}

