.select-interest-wrapper {
  height: 100vh;
  /*padding: 0px 20px;*/
  width: 300px;
}
.select-text {
  font-size: 32px;
  /*width: 300px;*/
  font-weight: 600;
  color: #4e3292;
  margin-bottom: 15px;
  /*position: absolute;*/
  /*top: 13%;*/
  /*left: 48%;*/
  /*transform: translate(-50%, -50%);*/
}
.select-description {
  

  font-size: 14px;
  font-weight: 400;
  color: #6a7587;
}
.interest-container {
  max-height: 350px;
  overflow-y: scroll;
  position: relative;
  /*width: 340px;*/
  /*margin: 40px;*/
  /*top: 60%;*/
  /*left: 53%;*/
  /*transform: translate(-50%, -50%);*/
}
.selectInterest{
  font-size: 14px;
  margin-bottom: 5px;
  max-width: fit-content;
  background-color: rgba(255, 255, 255, 0.4);
  width: fit-content;
  max-width: 200px;
  margin-right: 10px;
  margin-top: 5px;
  padding: 4px 8px 4px 8px;
  color: #6A7587;
  border: 1px solid white;
  border-radius: 12px;
}
.selectedInterest{
  font-size: 14px;
  margin-bottom: 5px;
  max-width: fit-content;
  background-color: #C387C3;
  max-width: 200px;
  margin-top: 5px;
  width: fit-content;
  margin-right: 10px;
  padding: 4px 8px 4px 8px;
  color: white;
  border: 1px solid white;
  border-radius: 12px;
}
.interest-error-message{
  /*position:absolute;*/
  color: #DF2040;
  font-size: 10px;
  font-weight: 400;
  margin-top: 5px;
  /*transform: translate(-50%,-50%);*/
  /*left: 50%;*/
  /*top:87%*/
}