.name-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.name-text-container{
    /*min-height: 100vh;*/
    width:300px;
    /*position: absolute;*/
    /*top: 17%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.name-text{
    font-size: 32px;
    width: 200px;
    font-weight: 600;
    color: #4e3292;
}
.simple_input-container{
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.nickname-text{
    padding-bottom: 5px;
    padding-left: 15px;
    color: #6A7587;
    font-size: 12px;
    font-weight: 400;
}

