.custom-toast {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  color: #6A7587;
  padding: 10px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 350px;
  min-width: 250px;
}

.toast-message {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  flex-grow: 1;
}

.toast-close-button {
  background: none;
  border: none;
  color: #6A7587;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}