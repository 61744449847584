/* social-share.css */
/* Modal overlay */
.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Container styles */
.share-container {
  position: relative;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem 1rem 3rem 1rem;
  max-width: 28rem;
  width: 90%;
  margin: 0 auto;
  animation: slideUp 0.3s ease-out;
}

/* Animation for modal appearance */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Close button */
.share-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
  color: #666;
}

.share-close-button:hover {
  color: #333;
}

/* Header styles */
.share-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.share-header-icon {
  color: #ec1d80;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}
.share-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 0px;
}
/* Grid layout for share buttons */
.share-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.75rem;
  margin-bottom: 1rem;
}

/* For mobile devices */
@media (max-width: 600px) {
  .share-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* General button styles */
.share-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}
.share-button:hover {
  transform: translateY(-2px);
}
.share-button-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.share-button-label {
  font-size: 0.75rem;
  font-weight: 500;
}
/* Platform-specific button styles */
.button-whatsapp {
  background-color: #25D366;
  color: white;
}
.button-whatsapp:hover {
  background-color: #1da851;
}
.button-email {
  background-color: #757575;
  color: white;
}
.button-email:hover {
  background-color: #5e5e5e;
}
.button-facebook {
  background-color: #1877F2;
  color: white;
}
.button-facebook:hover {
  background-color: #0d65d9;
}
.button-twitter {
  background-color: #000000;
  color: white;
}
.button-twitter:hover {
  background-color: #0d8fd9;
}
.button-linkedin {
  background-color: #0A66C2;
  color: white;
}
.button-linkedin:hover {
  background-color: #085296;
}
.button-telegram {
  background-color: #26A5E4;
  color: white;
}
.button-telegram:hover {
  background-color: #1a8bc4;
}
.button-copy {
  background-color: #f0f0f0;
  color: #333333;
}
.button-copy:hover {
  background-color: #e0e0e0;
}
/* Copy button states */
.icon-copy {
  color: #ec1d80;
}
.icon-check {
  color: #4CAF50;
}
/* Native share button */
.native-share-wrap{
  display: flex;
  gap: 20px;
}
button.share-button.button-copy{
  width: 30%;
}
.native-share-button {
  width: calc(70% - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ec1d80;
  color: white;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  margin-left: auto;
  transition: background-color 0.2s ease;
}
.native-share-button:hover {
  background-color: #d0176f;
}
.native-share-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  color: white;
}
/* Animation for button interaction */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}
.button-clicked {
  animation: pulse 0.3s ease-in-out;
}

/* Menu item style */
.menu-item-text-2 {
  cursor: pointer;
  transition: color 0.2s ease;
}

.menu-item-text-2:hover {
  color: #ec1d80;
}