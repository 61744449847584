.inner-pages-container-wrap{
	width: 340px;
	padding-top: 15px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex: 1;
}
.page-title{
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	color: #000000;
	text-align: left;
}
.create-club-progress-wrap{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.create-club-progress-wrap div{
	width: 155px;
	height: 4px;
	border-radius: 6px;
	border: none;
}
.create-club-progress-wrap div.active{
	background: #C387C3;
}
.create-club-progress-wrap div.inactive{
	background: #E5E5E5;
}

.upload-profile-image-container{
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}
.upload-profile-image{
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #E5E5E5;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.upload-profile-image .upload-img-icon{
	width: 20px;
	height: 20px;
	margin: auto;
}
#club-pro-upload-lbl{
	margin: auto 0px auto 15px;
}
#club-pro-upload-lbl,
#club-cover-upload-lbl{	
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: #AFAFAF;
}
#club-cover-upload-lbl{
	display: flex;
	justify-content: center;
	position: absolute;
    bottom: 100px;
    left: 0px;
    width: 100%;
    margin: 0px;
}
#club-cover-upload-lbl img{
	margin-right: 10px;
}
.upload-cover-image{
	width: 100%;
    height: 203px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #E5E5E5;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.upload-cover-image-container{
	width: 100%;
}
.upload-cover-image-container .photo-icons-wrap{
	position: relative;
}